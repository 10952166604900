import React from "react";

import { Spinner } from "reactstrap";

import "./OverlayLoader.css"

const OverlayLoader = ({ isLoading }) => {
    if (isLoading) {
        return <div className="overlay-loader-wrapper"><Spinner color="primary" /></div>
    }
    return null
}

export default OverlayLoader;
