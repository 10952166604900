import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

import {
    Row, Col, Card, CardBody, CardTitle,
    Form, FormGroup, FormFeedback,
    Input, Label, Button
} from "reactstrap";

import './App.css';

import OverlayLoader from "./components/OverlayLoader/OverlayLoader";
import alertCall from "./components/CustomAlert";

import UploadedFiles from "./UploadedFiles/UploadedFiles";

function App() {
    const [files, setFiles] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // axios.get("/api/test")
        //     .then((data) => {
        //         console.log("useEffect data", data)
        //     })

        return () => {
            setFiles(null);
        }
    }, []);

    const handleFilesChange = useCallback((e) => {
        e.preventDefault();
        const selectedFiles = e.target.files
        const selectedFilesLength = selectedFiles?.length

        let finalFiles = selectedFiles
        if (selectedFilesLength) {
            finalFiles = []
            for (let index = 0; index < selectedFilesLength; index++) {
                finalFiles.push(selectedFiles[index])
            }
        }

        setFiles(finalFiles);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        setIsSubmitted(true);

        if (files) {
            const formData = new FormData();
            files.forEach((file) => {
                formData.append('files', file)
            })

            setIsLoading(true)
            axios.post("/api/upload", formData)
                .then((res) => {
                    setIsSubmitted(false);
                    alertCall(res.data.message, "success")

                    document.getElementById('uploadFiles').value = '';
                    setFiles(null)
                }).catch((e) => {
                    console.log("handleSubmit e", e);
                    alertCall(e, "danger")
                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }, [files]);

    return (
        <div className="bg-light">
            <div className="container vh-100 py-5">
                <Row>
                    <Col xs={12}>
                        <div id="custom-alert"></div>

                        <Card className="w-100">
                            <CardBody>
                                <OverlayLoader isLoading={isLoading} />

                                <CardTitle>File Uploader POC</CardTitle>
                                <Form onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <Label for="uploadFiles">
                                            Please select file(s) to upload
                                        </Label>
                                        <Input
                                            id="uploadFiles"
                                            name="file"
                                            type="file"
                                            multiple
                                            onChange={handleFilesChange}
                                            invalid={isSubmitted && !files?.length}
                                        />
                                        {(isSubmitted && !files?.length) && (<FormFeedback>
                                            No file(s) selected
                                        </FormFeedback>)
                                        }
                                    </FormGroup>

                                    <Button color="primary">
                                        Submit
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <UploadedFiles />
            </div>
        </div>
    );
}

export default App;
