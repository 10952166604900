import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { filesize } from "filesize";

import {
    Row, Col, Card, CardBody, CardTitle,
    Button,
    Table,
} from "reactstrap";

import OverlayLoader from "../components/OverlayLoader/OverlayLoader";
import alertCall from "../components/CustomAlert";

const UploadedFiles = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    const getUploadedFiles = useCallback(() => {
        setIsLoading(true)

        axios.get("/api/getUploadedList")
            .then((res) => {
                const formattedData = (res.data?.data || []).map(({ size, mtime, ...otherInfo }) => ({
                    ...otherInfo,
                    size: filesize(size, { base: 2, standard: "jedec" }),
                    modifiedOn: mtime ? new Date(mtime).toLocaleString() : "-"
                }))
                setData(formattedData)
            }).catch((e) => {
                console.log("getUploadedFiles e", e);
                alertCall(e, "danger")
            }).finally(() => {
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        getUploadedFiles()
    }, [getUploadedFiles])

    const refreshDataClick = useCallback(() => {
        getUploadedFiles()
    }, [getUploadedFiles])

    return (
        <Row>
            <Col xs={12}>
                <Card className="w-100 my-5">
                    <CardBody>
                        <OverlayLoader isLoading={isLoading} />

                        <CardTitle>Uploaded Files</CardTitle>
                        <Button color="primary" onClick={refreshDataClick}>Refresh</Button>

                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Date Modified</th>
                                    <th>Size</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    data.map((file, index) => (
                                        <tr key={`file-${index}`}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{file.name || "File name"}</td>
                                            <td>{file.modifiedOn || "Date Modified"}</td>
                                            <td>{file.size || "Size"}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default UploadedFiles;
