import React from 'react'
import { Alert } from 'reactstrap'
import ReactDOM from 'react-dom'

const defaultErrorMessage = "Something went wrong, please try again later!"

function alertCall(message, type) {
    ReactDOM.render(<Alert color={type || 'primary'}>
        {message || defaultErrorMessage}
    </Alert>, document.getElementById('custom-alert'));

    setTimeout(() => {
        ReactDOM.render(<></>, document.getElementById('custom-alert'));
    }, 3000);
}

export default alertCall;
